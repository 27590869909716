.usa-top-banner {
  color: #1b1b1b;
  background-color: #f0f0f0;
}

.hide-me {
  display: none;
}

.usa-top-banner .container.top {
  align-items: flex-start;
  gap: .5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 1.06rem;
  line-height: 1.5;
  display: flex;
}

.usa-top-banner .container.top img {
  float: left;
  width: 1rem;
  margin-right: .5rem;
}

.usa-top-banner .container.top .action {
  align-items: flex-start;
  gap: .5rem;
  font-size: .7rem;
  line-height: 1.1;
  display: flex;
}

@media (width <= 40em) {
  .usa-top-banner .container.top .action {
    flex-direction: column;
    gap: 2px;
  }
}

.usa-top-banner .container.top .action button {
  color: #005ea2;
  box-shadow: none;
  text-align: left;
  vertical-align: baseline;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: .7rem;
  font-weight: normal;
  line-height: 1.1;
  text-decoration: underline;
  top: 0;
  bottom: 0;
  left: 0;
}

.usa-top-banner .container.top .action button:visited {
  color: #54278f;
}

.usa-top-banner .container.top .action button:hover {
  color: #1a4480;
}

.usa-top-banner .container.top .action button:active {
  color: #162e51;
}

.usa-top-banner .container.top .action button:focus {
  outline: "0.25rem solid #2491ff";
  outline-offset: 0;
}

.usa-banner {
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  flex-direction: column;
  gap: .25rem;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
}

@media (width >= 40em) {
  .usa-banner {
    flex-direction: row;
    gap: 1.5rem;
    padding-bottom: 0;
    font-size: .75rem;
  }
}

.banner-column {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.banner-icon {
  flex-shrink: 0;
  width: 3.5rem;
  margin-right: .5rem;
}

.banner-body {
  flex: 1;
}

.banner-body p {
  margin-top: 0;
}

.banner-img {
  float: left;
  width: 1rem;
  margin-top: 1rem;
  margin-right: .5rem;
}

button.banner-button {
  color: #005ea2;
  box-shadow: none;
  text-align: left;
  vertical-align: baseline;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: .8rem;
  font-weight: normal;
  line-height: 1.1;
  text-decoration: underline;
  position: absolute;
  inset: 0;
}

button.banner-button:visited {
  color: #54278f;
}

button.banner-button:hover {
  color: #1a4480;
}

button.banner-button:active {
  color: #162e51;
}

button.banner-button:focus {
  outline-offset: 0;
  outline: .25rem solid #2491ff;
}

.usa-banner__content {
  background-color: #0000;
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  padding: .25rem 1rem 1rem .5rem;
  font-size: 1rem;
  overflow: hidden;
}

@media (width >= 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 40em) {
  .usa-banner__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.usa-banner__content p:first-child {
  margin: 0;
}

.usa-accordion__content {
  background-color: #fff;
  margin-top: 0;
  padding: 1rem 1.25rem .75rem;
  overflow: auto;
}

.usa-accordion__content > :first-child {
  margin-top: 0;
}

.usa-accordion__content > :last-child {
  margin-bottom: 0;
}

.usa-banner .usa-accordion {
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
}

.usa-accordion {
  color: #1b1b1b;
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.06rem;
  line-height: 1.5;
  list-style-type: none;
}

.usa-accordion > li {
  max-width: unset;
  margin-bottom: 0;
}

.usa-accordion > ul li ul {
  list-style: disc;
}

.usa-accordion > ul li ul > li > ul {
  list-style: circle;
}

.usa-accordion > ul li ul > li > ul > li > ul {
  list-style: square;
}

.usa-accordion + .usa-accordion, .usa-accordion + .usa-accordion--bordered {
  margin-top: .5rem;
}

/*# sourceMappingURL=index.beb5d7af.css.map */
