.usa-top-banner {
  color: #1b1b1b;
  background-color: #f0f0f0;
}

.hide-me {
  display: none;
}

.usa-top-banner .container.top {
  font-size: 1.06rem;
  line-height: 1.5;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  padding-top: 2px;
  padding-bottom: 2px;
}

.usa-top-banner .container.top img {
  float: left;
  margin-right: 0.5rem;
  width: 1rem;
}

.usa-top-banner .container.top .action {
  font-size: 0.7rem;
  line-height: 1.1;
  display: flex;
  gap: .5rem;
  align-items: flex-start;
}

@media all and (max-width: 40em) {
  .usa-top-banner .container.top .action {
    flex-direction: column;
    gap: 2px;
  }

}

.usa-top-banner .container.top .action button {
  color: #005ea2;
  background-color: transparent;

  border: 0;
  border-radius: 0;
  box-shadow: none;

  font-size: 0.7rem;
  font-weight: normal;
  text-align: left;
  text-decoration: underline;
  line-height: 1.1;
  margin: 0;
  padding: 0;
  left: 0;
  bottom: 0;
  top: 0;
  vertical-align: baseline;
  height: auto;
  width: auto;
}

.usa-top-banner .container.top .action button:visited {
  color: #54278f;
}

.usa-top-banner .container.top .action button:hover {
  color: #1a4480;
}

.usa-top-banner .container.top .action button:active {
  color: #162e51;
}

.usa-top-banner .container.top .action button:focus {
  outline: "0.25rem solid #2491ff";
  outline-offset: 0;
}

.usa-banner {
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;

}

@media all and (min-width: 40em) {
  .usa-banner {
    font-size: 0.75rem;
    padding-bottom: 0;
  }
}

@media all and (min-width: 40em) {
  .usa-banner {
    flex-direction: row;
    gap: 1.5rem;
  }
}

.banner-column {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.banner-icon {
  width: 3.5rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.banner-body {
  flex: 1 1 0%;
}

.banner-body p {
  margin-top: 0px;
  ;
}

.banner-img {
  float: left;
  margin-top: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}

button.banner-button {

  color: #005ea2;
  background-color: transparent;

  border: 0;
  border-radius: 0;
  box-shadow: none;

  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
  text-decoration: underline;
  line-height: 1.1;

  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  vertical-align: baseline;
  height: auto;

  width: 100%;
  position: absolute;

}


button.banner-button:visited {
  color: #54278f;
}

button.banner-button:hover {
  color: #1a4480;
}

button.banner-button:active {
  color: #162e51;
}

button.banner-button:focus {
  outline: 0.25rem solid #2491ff;
  outline-offset: 0;
}




/*-----------------------------------------------*/

.usa-banner__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  background-color: transparent;
  font-size: 1rem;
  overflow: hidden;
  padding-top: 0.25rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  width: 100%;
}

@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (min-width: 64em) {
  .usa-banner__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (min-width: 40em) {
  .usa-banner__content {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}

.usa-banner__content p:first-child {
  margin: 0;
}

.usa-accordion__content {
  background-color: white;
  margin-top: 0;
  overflow: auto;
  padding: 1rem 1.25rem calc(1rem - 0.25rem) 1.25rem;
}

.usa-accordion__content>*:first-child {
  margin-top: 0;
}

.usa-accordion__content>*:last-child {
  margin-bottom: 0;
}


.usa-banner .usa-accordion {
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
}

.usa-accordion {
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  color: #1b1b1b;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: Source Sans 3, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.06rem;
  line-height: 1.5;
}

.usa-accordion>li {
  margin-bottom: 0;
  max-width: unset;
}

.usa-accordion>ul li ul {
  list-style: disc;
}

.usa-accordion>ul li ul>li>ul {
  list-style: circle;
}

.usa-accordion>ul li ul>li>ul>li>ul {
  list-style: square;
}

.usa-accordion+.usa-accordion,
.usa-accordion+.usa-accordion--bordered {
  margin-top: 0.5rem;
}